import website from '../assets/website.png'
//import instagram from '../assets/instagram.png'
//import appstore from '../assets/IMG_20201124_112759.png'
//import youtube from '../assets/images (3).jpeg'
//import dribbble from '../assets/dribbble.png'
import github from '../assets/github.png'
import gcal from '../assets/gcal.png'
//import telegram from '../assets/IMG_20201124_104554.png'

const items = [{
    "title": "Website",
    "subtitle": "view my portfolio, resume and other stuff",
    "image": website,
    "link": "https://defo.one/"
},
{
  "title": "Bookings",
  "subtitle": "book an appointment to talk with me",
  "image": gcal,
  "link": "https://cal.defo.one/"
},
{
    "title": "GitHub",
    "subtitle": "@alexdevz | view my public projects and involvements",
    "image": github,
    "link": "https://github.com/alexdevz"
}
//{
  //  "title": "Instagram",
  //  "subtitle": "",
  //  "image": instagram,
  //  "link": "https://instagram.com/"
//}
]

export default items
